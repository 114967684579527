import React, { Fragment, ReactNode, RefObject, useState } from "react"
import {
  Box,
  Icon,
  Drawer,
  Link as ChakraLink,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  List,
  ListItem,
  forwardRef,
  DrawerFooter,
  Flex,
  ButtonProps,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from "@chakra-ui/react"
import { MdBrightness2, MdLanguage, MdSearch, MdWbSunny } from "react-icons/md"
import { GrLanguage } from "react-icons/gr";
import { CgClose } from "react-icons/cg";
import { IoIosArrowBack } from "react-icons/io";
import { RiMenuAddLine } from "react-icons/ri";
import { useTranslation } from "gatsby-plugin-react-i18next"
import { motion } from "framer-motion"

import { BaseLink } from "../Link"
import { Button } from "../Buttons"
import Translation from "../Translation"

import { ISections } from "./types"
import { ChildOnlyProp } from "../../types"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "../GatsbyImage"
import { SearchIcon } from "../icons/SearchIcon";
import { Language, languageMetadata } from "../../utils/languages";
import { TranslationKey } from "../../utils/translations";

const NavListItem = forwardRef<{ "aria-label"?: string }, typeof List>(
  (props, ref) => <ListItem ref={ref} mb={12} {...props} />
)

const SectionItem = forwardRef<ChildOnlyProp, typeof ListItem>((props, ref) => (
  <ListItem ref={ref} mb={4} opacity={0.7} _hover={{ opacity: 1 }} {...props} />
))

const StyledNavLink = (props: {
  to: string
  isPartiallyActive: boolean
  children: ReactNode
}) => (
  <BaseLink
    color="currentColor"
    textDecor="none"
    _hover={{
      color: "primary.base",
    }}
    {...props}
  />
)

const FooterItem = forwardRef<ChildOnlyProp, "div">((props, ref) => (
  <Flex
    ref={ref}
    flex="1 1 120px"
    alignItems="center"
    color="text"
    cursor="pointer"
    flexDir="column"
    _hover={{
      color: "primary.base",
      "& svg": {
        fill: "currentColor",
      },
    }}
    sx={{
      "& svg": {
        fill: "currentColor",
        fontSize: "2xl",
      },
    }}
    {...props}
  />
))

const FooterItemText = (props: ChildOnlyProp) => (
  <Box
    fontSize="sm"
    lineHeight={1.6}
    fontWeight={400}
    letterSpacing="0.04em"
    mt={2}
    textTransform="uppercase"
    textAlign="center"
    opacity={0.7}
    _hover={{ opacity: 1 }}
    {...props}
  />
)

const hamburgerSvg =
  "M 2 13 l 10 0 l 0 0 l 10 0 M 4 19 l 8 0 M 12 19 l 8 0 M 2 25 l 10 0 l 0 0 l 10 0"
const glyphSvg =
  "M 2 19 l 10 -14 l 0 0 l 10 14 M 2 19 l 10 7 M 12 26 l 10 -7 M 2 22 l 10 15 l 0 0 l 10 -15"
const closeSvg =
  "M 2 13 l 0 -3 l 20 0 l 0 3 M 7 14 l 10 10 M 7 24 l 10 -10 M 2 25 l 0 3 l 20 0 l 0 -3"

const glyphPathVariants = {
  closed: {
    d: hamburgerSvg,
    transition: { duration: 0.4 },
  },
  open: {
    d: [hamburgerSvg, glyphSvg, glyphSvg, glyphSvg, closeSvg],
    transition: { duration: 1.2 },
  },
}

export interface IProps extends ButtonProps {
  isMenuOpen: boolean
  isDarkTheme: boolean
  toggleMenu: () => void
  toggleTheme: () => void
  toggleSearch: () => void
  linkSections: ISections
  fromPageParameter: string
  drawerContainerRef: RefObject<HTMLElement | null>
}

const MobileNavLanguage: React.FC<IProps> = ({
  isMenuOpen,
  isDarkTheme,
  toggleMenu,
  toggleTheme,
  toggleSearch,
  linkSections,
  fromPageParameter,
  drawerContainerRef,
  ...props
}) => {
  const { t } = useTranslation()

  const handleClick = (): void => {
    toggleMenu()
  }

  const [keyword, setKeyword] = useState<string>("")

  let translationsCompleted: Array<Language> = []
  for (const lang in languageMetadata) {
    const langMetadata = {
      ...languageMetadata[lang],
      name: t(`language-${lang}` as TranslationKey),
    }

    const nativeLangTitle = langMetadata.localName
    const englishLangTitle = langMetadata.name
    if (
      englishLangTitle.toLowerCase().includes(keyword.toLowerCase()) ||
      nativeLangTitle.toLowerCase().includes(keyword.toLowerCase())
    ) {
      translationsCompleted.push(langMetadata)
    }
  }
  translationsCompleted.sort((a, b) => a["name"].localeCompare(b["name"]))

  console.log("--translationsCompletedxx----", translationsCompleted)

  const data = useStaticQuery(graphql`
  query {

    nav: file(relativePath: { eq: "icon/nav.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 26
          layout: FIXED
          placeholder: BLURRED
          quality: 100
        )
      }
    }

    icon_search: file(relativePath: { eq: "icon/icon_search@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 26
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    icon_delet: file(relativePath: { eq: "icon/icon_delet@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 26
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`)

  return (
    <Flex
      justifyContent="center"
      alignContent={"center"}
      zIndex={192000}

    >



      <Drawer
        portalProps={{ containerRef: drawerContainerRef }}
        isOpen={isMenuOpen}
        onClose={handleClick}
        // placement="left"
        size="sm"
      >
        <DrawerContent p={0} zIndex={40000}>
          <DrawerBody
            p={0}
            // pt={"50px"}
            // bg={"red"}
            // width={"full"}
            zIndex={99999}
          >

            <Button
              // display={isMenuOpen ? "flex" : "none"}
              onClick={toggleMenu}
              aria-label={t("aria-toggle-search-button")}
              variant="ghost"
              isSecondary
              px={"15px"}
              // zIndex={2000}
              {...props}
            >
              <Icon
                as={IoIosArrowBack}
                color={'#4B4B4B'}
                width={{ base: "24px", md: "26px" }}
              />
            </Button>
            <List
              // borderTop={"1px dashed rgba(85, 69, 255, 0.20)"}
              pt={"5px"} px={"16px"} m={0} mb={4}
            //  bg={"red"}
            >
              {translationsCompleted.map((section, idx) => {

                return (
                  <NavListItem key={idx}
                    aria-label={`Select ${section.code}`}
                    borderTop={translationsCompleted.length-1 == idx ? "1px dashed rgba(85, 69, 255, 0.20)" : "none"}
                    // paddingBottom={"20px"}
                    m={0}
                    mb={4}

                  >
                    <Box color="text"   py={"20px"} fontSize="1.3rem" onClick={handleClick}>
                      <ChakraLink
                        textDecor="none"
                        color="rgba(20, 20, 58, 1)"
                        fontWeight="normal"
                        href={section.code == "en" ? "/" : "/" + section.code}
                       
                      >
                        {section.localName}
                      </ChakraLink>


                    </Box>

                  </NavListItem>
                )
              })}
            </List>
          </DrawerBody>

        </DrawerContent>
      </Drawer>
    </Flex>
  )
}

export default MobileNavLanguage
