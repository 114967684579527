import {
  Box,
  Flex,
  Heading,
  Icon,
  List,
  ListItem,
  SimpleGrid,
  useToken,
} from "@chakra-ui/react"
import { graphql, useStaticQuery } from "gatsby"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import { FaDiscord, FaGithub, FaTwitter } from "react-icons/fa"

import { Lang } from "../utils/languages"
import { getLocaleTimestamp } from "../utils/time"
import { isLangRightToLeft, TranslationKey } from "../utils/translations"
import { BaseLink } from "./Link"
import Translation from "./Translation"

const socialLinks = [
  {
    icon: FaGithub,
    to: "https://github.com/ethereum/ethereum-org-website",
    ariaLabel: "GitHub",
    color: "#333",
  },
  {
    icon: FaTwitter,
    to: "https://twitter.com/ethdotorg",
    ariaLabel: "Twitter",
    color: "#1DA1F2",
  },
  {
    icon: FaDiscord,
    to: "https://discord.gg/CetY6Y4",
    ariaLabel: "Discord",
    color: "#7289da",
  },
]
export interface LinkSection {
  title: TranslationKey
  links: Array<{
    to: string
    text: TranslationKey
    isPartiallyActive?: boolean
  }>
}

export interface IProps {}

const Footer: React.FC<IProps> = () => {
  const { language } = useI18next()
  const { t } = useTranslation()

  const isPageRightToLeft = isLangRightToLeft(language as Lang)

  const [medBp] = useToken("breakpoints", ["md"])

  //   官网：https://www.hobbyworld.ai
  // Hobbychain网址：https://www.hobbychain.org
  // Hobbychain区块链浏览器：https://scan.hobbychain.org
  // NFT交易市场：https://NFT.hobbychain.org
  // github: https://github.com/hobbyworld-project
  // 区块链浏览器：https://scan.hobbychain.org/#2
  // 推特：https://twitter.com/HobbyW1282
  // instagram ：https://www.instagram.com/hobbyworldmeta

  const linkSections: Array<LinkSection> = [
    {

      // "": "Hobby元世界",
      // "footer-learn": "学习",
      // "": "参与",
      title: t("footer-hobby-world"),
      // title: "Hobby World",
      links: [
        {
          to: "https://www.hobbyworld.ai",
          text: "Hobby World",
        },
        {
          to: `https://www.hobbyworld.ai`,
          text: "Get HBY",
        },
        // {
        //   to: `/dapps/`,
        //   text: t("decentralized-applications-dapps"),
        // },
        // {
        //   to: "/layer-2/",
        //   text: t("layer-2"),
        // },
        // {
        //   to: "/run-a-node/",
        //   text: t("run-a-node"),
        // },
        // {
        //   to: `/stablecoins/`,
        //   text: t("stablecoins"),
        // },
        // {
        //   to: `/staking/`,
        //   text: t("stake-eth"),
        // },
      ],
    },
    {
      title: t("footer-learn"),
      links: [
        {
          to: `https://www.hobbyworld.ai`,
          text: t("learn-hub"),
        },
        {
          to: `https://www.hobbyworld.ai`,
          text: "What is HOBBY",
        },
        // {
        //   to: `/eth/`,
        //   text: t("what-is-ether"),
        // },
        // {
        //   to: `/wallets/`,
        //   text: t("ethereum-wallets"),
        // },
        // {
        //   to: "/gas/",
        //   text: "Gas fees",
        // },
        // {
        //   to: "/security/",
        //   text: t("ethereum-security"),
        // },
        // {
        //   to: "/web3/",
        //   text: t("web3"),
        // },
        // {
        //   to: "/smart-contracts/",
        //   text: t("smart-contracts"),
        // },
        // {
        //   to: "/energy-consumption/",
        //   text: t("energy-consumption"),
        // },
        // {
        //   to: "/roadmap/",
        //   text: t("ethereum-roadmap"),
        // },
        // {
        //   to: "/eips/",
        //   text: t("eips"),
        // },
        // {
        //   to: "/history/",
        //   text: t("history-of-ethereum"),
        // },
        // {
        //   to: "/whitepaper/",
        //   text: t("ethereum-whitepaper"),
        // },
        // {
        //   to: `/glossary/`,
        //   text: t("ethereum-glossary"),
        // },
        // {
        //   to: "/governance/",
        //   text: t("ethereum-governance"),
        // },
        // {
        //   to: "/bridges/",
        //   text: t("bridges"),
        // },
        // {
        //   to: "/zero-knowledge-proofs/",
        //   text: t("zero-knowledge-proofs"),
        // },
        // {
        //   to: "/quizzes/",
        //   text: t("quizzes-title"),
        // },
      ],
    },
    {
      title: t("footer-get-involved"),
      links: [
        {
          to: `https://www.hobbyworld.ai`,
          text: t("get-started"),
          isPartiallyActive: false,
        },
        {
          to: `https://www.hobbyworld.ai`,
          text: t("documentation"),
        },
        // {
        //   to: `/developers/tutorials/`,
        //   text: t("tutorials"),
        // },
        // {
        //   to: `/developers/learning-tools/`,
        //   text: t("learn-by-coding"),
        // },
        // {
        //   to: `/developers/local-environment/`,
        //   text: t("set-up-local-env"),
        // },
      ],
    },
  ]

  const data = useStaticQuery(graphql`
    query {
      allSiteBuildMetadata {
        edges {
          node {
            buildTime
          }
        }
      }
    }
  `)

  return (
    <Box
      width={"auto"}
      as="footer"
      backgroundColor={"#FAFAFA"}
      // ={{base: "28px", md: "80px" }}
      padding={{ base: "20px 20px", sm: "0", md: "20px", lg: "40px 140px" }}
    >
      {/* <Flex
        fontSize="sm"
        justify="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Box color="text200">
          <Translation id="website-last-updated" />:{" "}
          {getLocaleTimestamp(
            language as Lang,
            data.allSiteBuildMetadata.edges[0].node.buildTime
          )}
        </Box>
        <Box my={4}>
          {socialLinks.map((link, idk) => {
            return (
              <BaseLink
                key={idk}
                to={link.to}
                hideArrow
                color="secondary"
                aria-label={link.ariaLabel}
              >
                <Icon
                  as={link.icon}
                  _hover={{
                    color: link.color,
                    transition:
                      "color 0.2s ease-in-out, transform 0.2s ease-in-out",
                  }}
                  fontSize="4xl"
                  ml={4}
                />
              </BaseLink>
            )
          })}
        </Box>
      </Flex> */}
      <SimpleGrid
        gap={4}
        justifyContent="space-between"
        templateColumns={{
          // base: "auto",
          base: "repeat(2, auto)",
          sm: "repeat(2, auto)",
          md: "repeat(3, auto)",
          xl: "repeat(3, auto)",
        }}
      >
        {linkSections.map((section: LinkSection, idx) => (
          <Box key={idx}>
            <Heading
              as="h3"
              // fontSize="26px"
              fontSize={{ base: "14px", md: "26px" }}
              lineHeight={{ base: "20px", md: "30px" }}
              my={{ base: "10px", md: "20px" }}
              // my="1.14em"
              color={"#1A1A1A"}
            >
              <Translation id={section.title} />
            </Heading>
            <List
              fontSize={{ base: "12px", md: "18px" }}
              lineHeight={{ base: "24px", md: "40px" }}
              color={"#666666"}
              fontWeight="400"
              m={0}
            >
              {section.links.map((link, linkIdx) => (
                <ListItem key={linkIdx} mb={{ base: 0, md: 2 }}>
                  <BaseLink
                    to={link.to}
                    hideArrow={true}
                    isPartiallyActive={false}
                    dir={isPageRightToLeft ? "auto" : "ltr"}
                    textDecor="none"
                    color="text200"
                    fontWeight="normal"
                    _hover={{
                      textDecor: "none",
                      color: "primary.base",
                      _after: {
                        color: "primary.base",
                      },
                      "& svg": {
                        fill: "primary.base",
                      },
                    }}
                    sx={{
                      "& svg": {
                        fill: "text200",
                      },
                    }}
                  >
                    {link.text}
                  </BaseLink>
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default Footer
