import React from "react"
import { useI18next } from "gatsby-plugin-react-i18next"
import { Flex, FlexProps, List, ListItem } from "@chakra-ui/react"

import NavDropdown from "./Dropdown"
import { getDirection } from "../../utils/translations"

import { Lang } from "../../utils/languages"
import { ISections } from "./types"
import {
  forwardRef,
  Icon,
  Link as ChakraLink,
  LinkProps,
  useTheme,
  VisuallyHidden,
} from "@chakra-ui/react"
import { BaseLink } from "../Link"

export interface IProps extends FlexProps {
  path: string
  sections: ISections
}

const Menu: React.FC<IProps> = ({ path, sections, ...props }) => {
  const { language } = useI18next()
  const direction = getDirection(language as Lang)
  const shouldShowSubNav = path.includes("/developers/")

  const { roadmap, ...restSections } = sections


  return (
    <Flex
      as={List}
      alignItems="center"
      m={0}
      gap={{ base: 12, xl: 12 }}
      {...props}
      pl={6}
     
    >
      <NavDropdown section={roadmap} hasSubNav={shouldShowSubNav} 
      
      >
        {roadmap.items.map((item, index) => (
          <NavDropdown.Item
            key={index}
            isLast={index === roadmap.items.length - 1}
            
            
          >
            <NavDropdown.Link 
              textAlign={"center"}
              to={item.to} 
              isPartiallyActive={false}
              color="#333333"
              // px={"20px"}
              minW={"180px"}
              py={0}
              _hover={{
                background: "rgba(85, 69, 255, 0.1)",
              }}
              hideArrow={true}
              >
              {item.text} 
            </NavDropdown.Link>
          </NavDropdown.Item>
        ))}
      </NavDropdown>

      {Object.keys(restSections).map((sectionKey) => {
        const section = restSections[sectionKey]
        console.log("section", section)
        return (
          <ListItem key={section.text} m={0}  >
            <BaseLink
              to={section.items[0].to}
              textDecor="none"
              color={"#333333"}
              fontWeight="normal"
              _hover={{
                textDecor: "none",
                color: "primary.base",
                _after: {
                  color: "primary.base",
                },
                "& svg": {
                  fill: "primary.base",
                  // fill: "#000",
                },
              }}
              sx={{
                "& svg": {
                  fill: "text200",
                  // fill: "#000",
                },
              }}
            >
              {section.items[0].text}
            </BaseLink>
          </ListItem>
        )
      })}
    </Flex>
  )
}

export default Menu
