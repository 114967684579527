import React, { FC, useEffect, useRef, useState } from "react"
import {
  Icon,
  Flex,
  Box,
  HStack,
  Link as ChakraLink,
  useDisclosure,
  ListItem,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  createIcon,
} from "@chakra-ui/react"
import {
  MdWbSunny,
  MdBrightness2,
  MdLanguage,
  MdClose,
  MdSearch,
} from "react-icons/md"
import { BiSolidDownArrow } from "react-icons/bi";
import { CgClose } from "react-icons/cg";
import { navigate as gatsbyNavigate } from "gatsby"

import Menu from "./Menu"
import MobileNavMenu from "./Mobile"
import { ButtonLink, IconButton } from "../Buttons"
import Link, { BaseLink } from "../Link"
import Search from "../Search"
import { EthHomeIcon } from "../icons"
import { useNav } from "./useNav"
import lofo from "../../assets/poap-logo.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { Language, languageMetadata } from "../../utils/languages"
import { TranslationKey } from "../../utils/translations"
import { SearchIcon } from "../icons/SearchIcon";
// import Input from "../Input"



export interface IProps {
  path: string
}



// TODO display page title on mobile
const Nav: FC<IProps> = ({ path }) => {
  const {
    ednLinks,
    fromPageParameter,
    i18n,
    isDarkTheme,
    shouldShowSubNav,
    t,
    toggleColorMode,
    linkSections,
    mobileNavProps,
  } = useNav({ path })
  const searchModalDisclosure = useDisclosure()

  const navWrapperRef = useRef(null)
  const [inputValue, setInputValue] = useState<string>("")
  const handleChange = (event) => setInputValue(event.target.value)

  const [keyword, setKeyword] = useState<string>("")
  const [showLang, setShowLang] = useState<boolean>(false)
  const resetKeyword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setKeyword("")
  }

  const redirectTo = "/zh"
  // location.search.split("from=").length > 1
  //   ? location.search.split("from=")[1]
  //   : "/"

  const handleSubmit = () => {
    setShowLang(!showLang)
  }

  const gotoNav = (link) => {
    // gatsbyNavigate("/" + link)
    // handleSubmit()

    console.log(link)
  }

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "pic_logo@.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 350
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      placeholderImageM: file(relativePath: { eq: "pic_logo@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 264
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }

      icon_search: file(relativePath: { eq: "icon/icon_search@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 22
            layout: FIXED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      icon_delet: file(relativePath: { eq: "icon/icon_delet@2x.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 22
            layout: FIXED
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)


  let translationsCompleted: Array<Language> = []
  for (const lang in languageMetadata) {
    const langMetadata = {
      ...languageMetadata[lang],
      name: t(`language-${lang}` as TranslationKey),
    }

    const nativeLangTitle = langMetadata.localName
    const englishLangTitle = langMetadata.name
    if (
      englishLangTitle.toLowerCase().includes(keyword.toLowerCase()) ||
      nativeLangTitle.toLowerCase().includes(keyword.toLowerCase())
    ) {
      translationsCompleted.push(langMetadata)
    }
  }
  translationsCompleted.sort((a, b) => a["name"].localeCompare(b["name"]))

  // console.log("--translationsCompleted----", translationsCompleted)

  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    console.log("isFocused: ", isFocused)
  }, [isFocused])

  const inputOnFocus = () => {
    console.log("-=-=-inputOnFocus=-=-=-=-")
    setIsFocused(true)
  }

  const inputOnBlur = () => {
    console.log("-=-=-inputOnBlur=-=-=-=-")
    setIsFocused(false)
  }

  const handleMouseLeave = () => {
    console.log('Mouse left the component!');
    setShowLang(false)
  };

  return (
    <Box position="sticky" top={0} zIndex={100} width="full">
      <Flex
        ref={navWrapperRef}
        as="nav"
        aria-label={t("nav-primary")}
        bg="background.base"
        borderBottom="1px"
        borderColor="rgba(0, 0, 0, 0.1)"
        // height="4.75rem"
        justifyContent="center"
        // height="4.75rem"
        height={{ base: "2.75rem", md: "80px" }}
        py={{ base: 1, xl: 0 }}
        px={{ base: 4, xl: 0 }}
        fontSize={{ base: "16px", md: "20px" }}
        
       
      >
        <Flex
          alignItems={{ base: "center", lg: "normal" }}
          justifyContent={{ base: "space-between", lg: "space-between" }}
          width="full"
          maxW="container.2xl"
          zIndex={mobileNavProps.isMenuOpen ? "1500" : 1000}
        >
          <ChakraLink
            href="/"
            aria-label={t("home")}
            display="inline-flex"
            alignItems="center"
            textDecor="none"
            width={{ base: "183px", md: "264px" }}
          >
            {/* <EthHomeIcon opacity={0.85} _hover={{ opacity: 1 }} /> */}
            {/* <GatsbyImage
              image={data.placeholderImage.childImageSharp.gatsbyImageData}
              alt="xxxx"
            /> */}
            <GatsbyImage
              image={data.placeholderImageM.childImageSharp.gatsbyImageData}
              alt="xxxx"
            />
          </ChakraLink>
          {/* Desktop */}

          <Menu
            // display={{ base: "none", lg: "flex" }}
            hideBelow="lg"
            path={path}
            sections={linkSections}
          />
          <Flex
            alignItems="center"
            justifyContent="end"
            gap={{ base: 2, xl: 4 }}
            px={"3px"}
            width={{ base: "100px", lg: "450px" }}
          >
            {/* <Search {...searchModalDisclosure} /> */}

            {/* <Flex
              display={{ base: "none", md: "flex" }}
              textColor={"#5545FF"}
              alignItems={{ base: "center", lg: "center" }}
              justifyContent={{ base: "end", lg: "end" }}
              // width={!isFocused ? "128" : "150px"}
              border={isFocused ? "1px solid #5545FF" : "none"}
              // borderRadius: "42px",
              borderRadius={"42px"}
              // border={!isFocused ? "1px solid #5545F" : "none"}
              // borderColor={isFocused ? " #5545F" : "none"}
              transition={"all .5s"}
            >
              <Box transition={"all .5s"}>
                <GatsbyImage
                  image={data.icon_search.childImageSharp.gatsbyImageData}
                  alt="xxxx"
                />
              </Box>

              <Input
                transition={"all .5s"}
                placeholder={t("nav-search")}
                border={"none"}
                width={!isFocused ? "110px" : "140px"}
                // bg={"transparent"}
                // backgroundColor={"red"}

                _active={{
                  background: "transparent",
                }}
                _hover={{
                  transition: "all .5s",
                  background: "transparent",
                }}
                _focus={{
                  borderRadius: "base",
                  boxShadow: "tableBoxHover",
                  // background: "tableBackgroundHover",
                  transition: "transform 0.1s",
                  transform: "scale(1.02)",

                  border: "none",
                }}
                _focusVisible={{
                  transition: "all .5s",
                  // border: "none",
                  boxShadow: "none",
                  // border: "1px solid #5545FF",

                  boxSize: "content-box",
                }}
                // px={"40px"}
                onFocus={inputOnFocus}
                onBlur={inputOnBlur}
              />
              <Box opacity={isFocused ? "1" : "0"} transition={"all .5s"}>
                <GatsbyImage
                  image={data.icon_delet.childImageSharp.gatsbyImageData}
                  alt="xxxx"
                />
              </Box>
            </Flex> */}
            <Box  display={{base: "none", md:"block"}}>
              <InputGroup >
                <InputLeftElement pointerEvents='none' paddingLeft={"15px"}>
                  <Icon
                    as={SearchIcon}
                    color={'#666666'}
                    width={{ base: "14px", md: "20px" }}
                  />
                </InputLeftElement>
                <Input
                  // px={"40px"}
                  color='#5545FF'
                  variant="unstyled"
                  // placeholder='custom placeholder'
                  placeholder={t("nav-search")}
                  textIndent={"5px"}
                  width={{base: "100px", md:isFocused ? "220px" : "160px"}}
                  borderRadius={"full"}
                  border="none"
                  value={inputValue}
                  onChange={handleChange}

                  // _focus={{
                  //   borderRadius: "base",
                  //   boxShadow: "tableBoxHover",
                  //   // background: "tableBackgroundHover",
                  //   transition: "transform 0.1s",
                  //   // transform: "scale(1.02)",

                  //   border: "none",
                  // }}
                  _focusVisible={{
                    transition: "all .5s",
                    // border: "none",
                    boxShadow: "none",
                    border: "1px solid #5545FF",

                    boxSize: "content-box",
                  }}
                  transition={"all .5s"}
                  _placeholder={{ color: "#666" }}

                  onFocus={inputOnFocus}
                  onBlur={inputOnBlur}
                />
                <InputRightElement 
                  pointerEvents='visiblePainted' 
                  display={isFocused ? "flex" : "none"}
                  transition={"all .5s"}
                  cursor={"pointer"}
                  onClick={()=>{setInputValue("")}}
                  >
                  <Icon
                    as={CgClose}
                    color={'#666666'}
                    width={{ base: "14px", md: "20px" }}
                  />
                </InputRightElement>
              </InputGroup>
            </Box>

            {/* Mobile */}
            <MobileNavMenu
              {...mobileNavProps}
              hideFrom="lg"
              toggleSearch={searchModalDisclosure.onOpen}
              drawerContainerRef={navWrapperRef}
            />

            {/* Language */}
            <HStack

              spacing={2} hideBelow="lg"
              onMouseLeave={handleMouseLeave}
              position={"relative"}>

              <Button
                variant="outline"
                color={'#666666'}
                fontSize={{ base: "14px", md: "20px" }}
                leftIcon={<Icon
                  as={MdLanguage}
                  // color={'#666666'}
                  width={{ base: "14px", md: "22px" }}
                />}
                rightIcon={<Icon
                  as={BiSolidDownArrow}
                  // color={'#666666'}
                  width={{ base: "14px", md: "14px" }}
                />}

                onClick={() => handleSubmit()}
                border={"node"}
                textColor={"#666666"}
                _hover={{
                  transition: "transform 0.5s",
                  transform: "skewX(-5deg)",
                  boxShadow: "tableBoxShadow",
                }}
              >
                {t("nav-languages")}
                {/* {i18n.language.toUpperCase()} */}
              </Button>

              <Box
                display={showLang ? "blcok" : "none"}
                transition={"all .5"}
                background={"#fff"}
                position={"absolute"}
                top={"50px"}
                right={"2px"}
                border={"1px solid #5545FF;"}
                borderColor="rgba(85, 69, 255, 0.4)"
              >
                {translationsCompleted.map((item) => {
                  console.log("item", item)
                  return (
                    <ChakraLink
                      width={"200px"}
                      px={"0px"}
                      height={"77px"}
                      lineHeight={"77px"}
                      display={"block"}
                      textAlign={"center"}
                      
                      fontSize={"22px"}
                      key={item.name}
                      href={item.code == "en" ? "/" : "/" + item.code}
                      // onClick={() => gotoNav(item)}
                      textDecor="none"
                      color="text200"
                      fontWeight="normal"
                      _hover={{
                        textDecor: "none",
                        color: "#5545FF",
                        _after: {
                          color: "primary.base",
                        },
                        "& svg": {
                          fill: "primary.base",
                        },
                        background: "rgba(85, 69, 255, 0.1)",
                      }}
                      sx={{
                        "& svg": {
                          fill: "text200",
                        },
                      }}
                    >
                      {item.localName}
                    </ChakraLink>
                  )
                })}
              </Box>
            </HStack>
          </Flex>
        </Flex>
      </Flex>

    </Box>
  )
}

export default Nav
