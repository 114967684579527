import { useColorMode } from "@chakra-ui/react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { cloneDeep } from "lodash"
import { useRef, useState } from "react"
import { IItem, ISections } from "./types"

import { trackCustomEvent } from "../../utils/matomo"

export const useNav = ({ path }: { path: string }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { colorMode, toggleColorMode } = useColorMode()
  const { t, i18n } = useTranslation()

  const isDarkTheme = colorMode === "dark"

  const linkSections: ISections = {
    roadmap: {
      text: t("nav-roadmap"),
      ariaLabel: t("use-ethereum-menu"),
      items: [
        {
          text: t("nav-roadmap-world"),
          // to: "https://www.hobbyworld.ai",
          to: "/roadmap",
        },
        {
          // text: "Hobby Chain",
          text: t("nav-roadmap-chain"),
          to: "https://hobbychain.org",
        },
      ],
    },

    developers: {
      text: t("nav-developers"),
      ariaLabel: t("page-developers-aria-label"),
      items: [
        {
          // text: "Developers",
          text: t("nav-developers"),
          to: "/developers",
        },
      ],
    },
    blog: {
      text: "Blog",
      ariaLabel: t("enterprise-menu"),
      items: [
        {
          // text: "Blog",
          text: t("nav-blog"),
          to: "/blog",
        },
      ],
    },
    partner: {
      // text: "Partner",
      text: "Partner",

      ariaLabel: t("community-menu"),
      items: [
        {
          // text: "Partner",
          text: t("nav-partner"),
          to: "/partner",
        },
      ],
    },
  }

  const ednLinks: Array<IItem> = [
    {
      text: t("home"),
      to: "/developers/",
      isPartiallyActive: false,
    },
    {
      text: t("docs"),
      to: "/developers/docs/",
    },
    {
      text: t("tutorials"),
      to: "/developers/tutorials/",
    },
    {
      text: t("learn-by-coding"),
      to: "/developers/learning-tools/",
    },
    {
      text: t("set-up-local-env"),
      to: "/developers/local-environment/",
    },
  ]

  let mobileLinkSections = cloneDeep(linkSections)
  const toggleMenu = (): void => {
    setIsMenuOpen((prev) => !prev)
  }

  const shouldShowSubNav = path.includes("/developers/")
  const splitPath = path.split("/")
  const fromPageParameter =
    splitPath.length > 3 && splitPath[2] !== "languages"
      ? `?from=/${splitPath.slice(2).join("/")}`
      : ""

  const changeColorMode = () => {
    toggleColorMode()
    trackCustomEvent({
      eventCategory: "nav bar",
      eventAction: "click",
      eventName: isDarkTheme ? "light mode" : "dark mode", // This will be inverted as the state is changing
    })
  }

  const mobileNavProps = {
    isMenuOpen,
    isDarkTheme,
    toggleMenu,
    toggleTheme: changeColorMode,
    linkSections: mobileLinkSections,
    fromPageParameter,
  }

  return {
    toggleColorMode: changeColorMode,
    t,
    i18n,
    isDarkTheme,
    ednLinks,
    linkSections,
    shouldShowSubNav,
    fromPageParameter,
    mobileNavProps,
  }
}
