exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-four-tsx": () => import("./../../../src/pages/blog/four.tsx" /* webpackChunkName: "component---src-pages-blog-four-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-one-tsx": () => import("./../../../src/pages/blog/one.tsx" /* webpackChunkName: "component---src-pages-blog-one-tsx" */),
  "component---src-pages-blog-there-tsx": () => import("./../../../src/pages/blog/there.tsx" /* webpackChunkName: "component---src-pages-blog-there-tsx" */),
  "component---src-pages-blog-two-tsx": () => import("./../../../src/pages/blog/two.tsx" /* webpackChunkName: "component---src-pages-blog-two-tsx" */),
  "component---src-pages-developers-index-tsx": () => import("./../../../src/pages/developers/index.tsx" /* webpackChunkName: "component---src-pages-developers-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-partner-tsx": () => import("./../../../src/pages/partner.tsx" /* webpackChunkName: "component---src-pages-partner-tsx" */),
  "component---src-pages-roadmap-index-tsx": () => import("./../../../src/pages/roadmap/index.tsx" /* webpackChunkName: "component---src-pages-roadmap-index-tsx" */),
  "component---src-pages-roadmap-vision-tsx": () => import("./../../../src/pages/roadmap/vision.tsx" /* webpackChunkName: "component---src-pages-roadmap-vision-tsx" */),
  "component---src-templates-static-tsx": () => import("./../../../src/templates/static.tsx" /* webpackChunkName: "component---src-templates-static-tsx" */)
}

