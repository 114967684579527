import * as React from "react"
import { createIcon } from "@chakra-ui/react"

export const SearchIcon = createIcon({
  displayName: "SearchIcon",
  viewBox: "0 0 1024 1024",
  defaultProps: {
    width: "256",
    height: "256",
    fillRule: "evenodd",
    clipRule: "evenodd",
  },
  path: [
    <path d="M996.237585 928.29942L821.020476 752.008879A486.182064 486.182064 0 0 0 908.298744 473.990025c0-257.623648-202.135478-461.080273-451.419387-461.080273C214.696614 6.138874 12.561136 216.366377 12.561136 467.219147c0 257.623648 202.135478 461.080273 451.419386 461.080273 114.526923 0 215.594662-47.478717 296.432339-115.600355l175.134538 183.06142c20.230062 20.312634 47.148431 20.312634 60.690186 0a45.579569 45.579569 0 0 0 0-67.791351z m-532.257063-74.314514c-202.135478 0-370.581709-169.519663-370.58171-379.829737s168.446232-379.829738 370.58171-379.829738 370.499138 169.519663 370.499138 379.829738-168.36366 379.582023-370.499138 379.582022z" p-id="7211" 
    fill="#666666"
    />,
    <path d="M968.410928 1024a59.781898 59.781898 0 0 1-41.285841-18.991487L759.669716 830.204262c-90.003134 72.497937-191.979161 110.728626-295.441479 110.728626a457.116832 457.116832 0 0 1-328.38758-137.646994 480.402047 480.402047 0 0 1 7.101165-676.674935A430.446179 430.446179 0 0 1 457.622502 0.111141a456.703974 456.703974 0 0 1 327.727007 137.812138 475.695461 475.695461 0 0 1 135.830417 336.066746 498.980675 498.980675 0 0 1-83.727686 276.615135l167.620515 168.693947a58.213036 58.213036 0 0 1 0.908288 84.883689 45.579569 45.579569 0 0 1-37.570115 19.817204zM761.321149 795.771871l183.309135 191.566302a33.028673 33.028673 0 0 0 26.01008 11.229749 20.064919 20.064919 0 0 0 15.110617-9.495744l1.568862-1.899148a33.028673 33.028673 0 0 0 0-49.955868L804.588711 753.330026l6.275448-8.752598a470.658588 470.658588 0 0 0 85.213976-270.587403 450.263383 450.263383 0 0 0-128.646681-318.231263A431.684754 431.684754 0 0 0 457.209644 25.625791a406.170104 406.170104 0 0 0-296.680054 119.068366 454.969969 454.969969 0 0 0-6.770878 640.756253 431.767326 431.767326 0 0 0 310.22181 130.215543c101.067739 0 200.731759-38.891262 288.340314-112.380059z m-297.258055 70.598788c-211.218363 0-383.132605-176.042826-383.132606-392.380634S252.762159 81.609392 463.980522 81.609392s383.132605 176.042826 383.132605 392.380633-171.914242 392.380634-383.132605 392.380634z m0-759.659476c-197.428892 0-357.948242 164.647934-357.948242 367.031127s160.51935 367.031127 357.948242 367.031127S821.928764 676.373218 821.928764 473.990025 661.326842 106.958898 463.980522 106.958898z" p-id="7212" 
    fill="#666666"
    />
  ],
})
