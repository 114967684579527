import React, { Fragment, ReactNode, RefObject, useState } from "react"
import {
  Box,
  Icon,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  List,
  ListItem,
  forwardRef,
  DrawerFooter,
  Flex,
  ButtonProps,
  InputGroup,
  InputRightElement,
  InputLeftElement,
} from "@chakra-ui/react"
import { MdBrightness2, MdLanguage, MdSearch, MdWbSunny } from "react-icons/md"
import { GrLanguage } from "react-icons/gr";
import { CgClose } from "react-icons/cg";
import { RiMenuAddLine } from "react-icons/ri";
import { useTranslation } from "gatsby-plugin-react-i18next"
import { motion } from "framer-motion"

import { BaseLink } from "../Link"
import { Button } from "../Buttons"
import Translation from "../Translation"

import { ISections } from "./types"
import { ChildOnlyProp } from "../../types"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "../GatsbyImage"
import { SearchIcon } from "../icons/SearchIcon";

import MobileLanguage from "./MobileLanguage"
import MobileSearch from "./MobileSearch"

const NavListItem = forwardRef<{ "aria-label"?: string }, typeof List>(
  (props, ref) => <ListItem ref={ref} mb={12} {...props} />
)

const SectionItem = forwardRef<ChildOnlyProp, typeof ListItem>((props, ref) => (
  <ListItem ref={ref} mb={4} opacity={0.7} _hover={{ opacity: 1 }} {...props} />
))

const StyledNavLink = (props: {
  to: string
  isPartiallyActive: boolean
  children: ReactNode
}) => (
  <BaseLink
    color="currentColor"
    textDecor="none"
    _hover={{
      color: "primary.base",
    }}
    {...props}
  />
)

const FooterItem = forwardRef<ChildOnlyProp, "div">((props, ref) => (
  <Flex
    ref={ref}
    flex="1 1 120px"
    alignItems="center"
    color="text"
    cursor="pointer"
    flexDir="column"
    _hover={{
      color: "primary.base",
      "& svg": {
        fill: "currentColor",
      },
    }}
    sx={{
      "& svg": {
        fill: "currentColor",
        fontSize: "2xl",
      },
    }}
    {...props}
  />
))

const FooterItemText = (props: ChildOnlyProp) => (
  <Box
    fontSize="sm"
    lineHeight={1.6}
    fontWeight={400}
    letterSpacing="0.04em"
    mt={2}
    textTransform="uppercase"
    textAlign="center"
    opacity={0.7}
    _hover={{ opacity: 1 }}
    {...props}
  />
)

const hamburgerSvg =
  "M 2 13 l 10 0 l 0 0 l 10 0 M 4 19 l 8 0 M 12 19 l 8 0 M 2 25 l 10 0 l 0 0 l 10 0"
const glyphSvg =
  "M 2 19 l 10 -14 l 0 0 l 10 14 M 2 19 l 10 7 M 12 26 l 10 -7 M 2 22 l 10 15 l 0 0 l 10 -15"
const closeSvg =
  "M 2 13 l 0 -3 l 20 0 l 0 3 M 7 14 l 10 10 M 7 24 l 10 -10 M 2 25 l 0 3 l 20 0 l 0 -3"

const glyphPathVariants = {
  closed: {
    d: hamburgerSvg,
    transition: { duration: 0.4 },
  },
  open: {
    d: [hamburgerSvg, glyphSvg, glyphSvg, glyphSvg, closeSvg],
    transition: { duration: 1.2 },
  },
}

export interface IProps extends ButtonProps {
  isMenuOpen: boolean
  isDarkTheme: boolean
  toggleMenu: () => void
  toggleTheme: () => void
  toggleSearch: () => void
  linkSections: ISections
  fromPageParameter: string
  drawerContainerRef: RefObject<HTMLElement | null>
}

const MobileNavMenu: React.FC<IProps> = ({
  isMenuOpen,
  isDarkTheme,
  toggleMenu,
  toggleTheme,
  toggleSearch,
  linkSections,
  fromPageParameter,
  drawerContainerRef,
  ...props
}) => {
  const { t } = useTranslation()

  const [isLgOpen, setIsLgOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const handleClick = (): void => {
    toggleMenu()
  }
  const toggleLGClick = (): void => {
    // toggleMenu()
    setIsLgOpen(!isLgOpen)
  }


  const toggleSearchClick = (): void => {
    // toggleMenu()
    setIsSearchOpen(!isSearchOpen)
  }

  


  const data = useStaticQuery(graphql`
  query {

    nav: file(relativePath: { eq: "icon/nav.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 26
          layout: FIXED
          placeholder: BLURRED
          quality: 100
        )
      }
    }

    icon_search: file(relativePath: { eq: "icon/icon_search@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 26
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    icon_delet: file(relativePath: { eq: "icon/icon_delet@2x.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 26
          layout: FIXED
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`)

  return (
    <Flex
      justifyContent="center"
      alignContent={"center"}
    >



      <Button
        display={isMenuOpen ? "flex" : "none"}
        onClick={() => {
          toggleMenu()
          setIsSearchOpen(!isSearchOpen)

        }}
        aria-label={t("aria-toggle-search-button")}
        variant="ghost"
        isSecondary
        px={"15px"}
        // zIndex={2000}
        {...props}
      >
        <Icon
          as={SearchIcon}
          color={'#4B4B4B'}
          width={{ base: "20px", md: "26px" }}
        />
      </Button>
      <Button
        display={isMenuOpen ? "flex" : "none"}
        onClick={() => {
          toggleMenu()
          setIsLgOpen(true)
          // toggleLGClick()

        }}
        aria-label={t("aria-toggle-search-button")}
        variant="ghost"
        isSecondary
        px={"15px"}
        // paddingTop={"5px"}
        // zIndex={2000}

        {...props}

      >
        <Icon
          as={MdLanguage}
          color={'#4B4B4B'}
          height={{ base: "24px", md: "26px" }}
        />
      </Button>

      <Button
        onClick={toggleMenu}
        aria-label={t("aria-toggle-search-button")}
        variant="ghost"
        isSecondary
        px={0}

        {...props}
      >

        {/* <div data-v-65c9a057="" class="layers_item has-tooltip" style="transform: translate3d(722.088px, 121.413px, 0px); width: 34.0808px; height: 31.9508px;"></div> */}
        {/* <Icon
          viewBox="0 0 24 40"
          pointerEvents={isMenuOpen ? "none" : "auto"}
          mx={0.5}
          width={6}
          height={10}
          position="relative"
          strokeWidth="2px"
          zIndex={100}
          _hover={{
            color: "primary.base",
            "& > path": {
              stroke: "primary.base",
            },
          }}
          sx={{
            "& > path": {
              stroke: "text",
              fill: "none",
            },
          }}
        >
          <motion.path
            variants={glyphPathVariants}
            initial={false}
            animate={isMenuOpen ? "open" : "closed"}
          />
        </Icon> */}


        <Box
          display={isMenuOpen ? "none" : "block"}
          width={"28px"}
          transition={"all .5s"}>
          <GatsbyImage
            image={data.nav.childImageSharp.gatsbyImageData}
            alt="xxxx"
          />
        </Box>

        <Box
          display={isMenuOpen ? "flex" : "none"}
          transition={"all .5s"}>
          {/* <GatsbyImage
            image={data.icon_delet.childImageSharp.gatsbyImageData}
            alt="xxxx"
          /> */}
          <Icon
            as={CgClose}
            color={'#4B4B4B'}
            width={{ base: "26px", md: "26px" }}
          />
        </Box>
        {/* 
        <Icon
          as={RiMenuAddLine}
          color={'#666666'}
          width={{ base: "14px", md: "20px" }}
        /> */}
      </Button>
      <Drawer
        portalProps={{ containerRef: drawerContainerRef }}
        isOpen={isMenuOpen}
        onClose={handleClick}
        placement="left"
        size="sm"

      >
        <DrawerOverlay bg="modalBackground" />
        <DrawerContent bg="background.base">
          <DrawerBody pt={12} pb={24}
            px={0}
          >
            <List
              borderTop={"1px dashed rgba(85, 69, 255, 0.20)"}
              pt={"5px"} px={"16px"} m={0} mb={4}
            //  bg={"red"}
            >
              {Object.keys(linkSections).map((sectionKey, idx) => {
                const section = linkSections[sectionKey]

                return section.items ? (
                  <NavListItem key={idx}
                    aria-label={`Select ${section.text}`}
                    borderBottom={"1px dashed rgba(85, 69, 255, 0.20)"}
                    // paddingBottom={"20px"}
                    m={0}
                    mb={4}
                    pb={section.text === "Roadmap" ? 4 : 0}
                  >
                    <Box color="text" my={"20px"} fontSize="1.3rem" onClick={handleClick}>
                      <StyledNavLink

                        to={section.items[0].to} isPartiallyActive={false}

                      >
                        {section.text}
                      </StyledNavLink>


                    </Box>
                    <List m={0} display={section.text === "Roadmap" ? "block" : "none"}>
                      {section.items.map((item, idx) =>
                        item.items ? (
                          <Fragment key={idx}  >
                            <Box
                              mt={8}
                              mb={4}
                              fontSize="0.9rem"
                              lineHeight={1}
                              color="currentColor"


                            >
                              {item.text}
                            </Box>
                            {item.items.map((item, idx) => (
                              <SectionItem key={idx} onClick={handleClick}


                              >
                                <StyledNavLink
                                  to={item.to}
                                  isPartiallyActive={item.isPartiallyActive}

                                >
                                  {item.text}
                                </StyledNavLink>
                              </SectionItem>
                            ))}
                          </Fragment>
                        ) : (
                          <SectionItem key={idx} onClick={handleClick}>
                            <StyledNavLink
                              to={item.to}
                              isPartiallyActive={item.isPartiallyActive}
                            >
                              {item.text}
                            </StyledNavLink>
                          </SectionItem>
                        )
                      )}
                    </List>
                  </NavListItem>
                ) : (
                  <NavListItem key={idx} onClick={handleClick}>
                    <StyledNavLink
                      to={section.to}
                      isPartiallyActive={section.isPartiallyActive}
                    >
                      {section.text}
                    </StyledNavLink>
                  </NavListItem>
                )
              })}
            </List>
          </DrawerBody>
          {/* <DrawerFooter
            bg="background.base"
            borderTop="1px"
            borderColor="lightBorder"
            justifyContent="space-between"
            height="108px"
            px={4}
            py={0}
            mt="auto"
          >
            <FooterItem
              onClick={() => {
                // Workaround to ensure the input for the search modal can have focus
                toggleMenu()
                toggleSearch()
              }}
            >
              <Icon as={MdSearch} />
              <FooterItemText>
                <Translation id="search" />
              </FooterItemText>
            </FooterItem>
            <FooterItem onClick={toggleTheme}>
              <Icon as={isDarkTheme ? MdWbSunny : MdBrightness2} />
              <FooterItemText>
                <Translation id={isDarkTheme ? "light-mode" : "dark-mode"} />
              </FooterItemText>
            </FooterItem>
            <FooterItem onClick={handleClick}>
              <Flex
                as={BaseLink}
                to={`/languages/${fromPageParameter}`}
                alignItems="center"
                color="text"
                flexDir="column"
                textDecor="none"
                _hover={{
                  color: "primary.base",
                  textDecor: "none",
                }}
              >
                <Icon as={MdLanguage} />
                <FooterItemText>
                  <Translation id="languages" />
                </FooterItemText>
              </Flex>
            </FooterItem>
          </DrawerFooter> */}
        </DrawerContent>
      </Drawer>

      <MobileLanguage
        // {...mobileNavProps}
        isMenuOpen={isLgOpen}
        isDarkTheme={isDarkTheme}
        toggleMenu={toggleLGClick}
        toggleTheme={toggleTheme}
        toggleSearch={toggleSearch}
        linkSections={linkSections}
        fromPageParameter={fromPageParameter}
        drawerContainerRef={drawerContainerRef}

        hideFrom="lg"
        // toggleSearch={searchModalDisclosure.onOpen}
        // drawerContainerRef={navWrapperRef}

        zIndex={4000}
      />

      <MobileSearch
        // {...mobileNavProps}
        isMenuOpen={isSearchOpen}
        isDarkTheme={isDarkTheme}
        toggleMenu={toggleSearchClick}
        toggleTheme={toggleTheme}
        toggleSearch={toggleSearch}
        linkSections={linkSections}
        fromPageParameter={fromPageParameter}
        drawerContainerRef={drawerContainerRef}

        hideFrom="lg"
        // toggleSearch={searchModalDisclosure.onOpen}
        // drawerContainerRef={navWrapperRef}

        zIndex={4000}
      />
    </Flex>
  )
}

export default MobileNavMenu
